<template>
  <simpleCard classes="w-100">
    <div class="d-flex bread_flex">
      <v-breadcrumbs divider=">>" :items="items">
        <template v-slot:item="{ item }">
          <router-link :to="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </router-link>
        </template>
      </v-breadcrumbs>
      <div class="filter_icon frm-action" v-if="search_route && canAddNew">
        <div>
          <router-link :to="search_route" class="btn w-100">
            {{ search_title }}
            <span class="fas fa-plus"></span>
          </router-link>
        </div>
      </div>
    </div>
  </simpleCard>
</template>

<script>
export default {
  props: [
    "items",
    "title",
    "search_title",
    "search_route",
    "icon",
    "canAddNew",
  ],
};
</script>

<style lang="scss" scoped>
.frm-action a.btn {
  background: linear-gradient(
    97.46deg,
    #00a0e6 -1.53%,
    #00a0e6 132.35%
  ) !important;
  color: #fff !important;
  height: 45px;
  font-size: 16px;
  min-width: 200px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
}
</style>
