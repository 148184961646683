<template>
  <div class="countries_all">
    <template>
      <!-- <the-filter :inputs="inputs" /> -->
      <!-- @filterFired="filterFired" -->
      <Breadcrumb
        :items="items"
        :canAddNew="!canAdd"
        search_route="/vehicle_models/add"
        :search_title="$t('breadcrumb.vehicle_models.add')"
        icon="fa-plus"
      />
      <!-- :custom-filter="helper_filterSearch" -->

      <main>
        <v-data-table
          class="thumb strip"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->

          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.image`]="{ item, index }">
            <img
              class="image"
              :src="item.image"
              @error="imageError(index)"
              @click="show_model_1(item.image)"
              v-if="item.image"
            />
            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>
          <template v-slot:[`item.vechile_brand.name`]="{ item }">
            <span class="redColor fontBold" v-if="item.vechile_brand.name">{{
              item.vechile_brand.name
            }}</span>
            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>

          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>
          <!-- IsActive -->
          <!-- Select no data State -->
          <template v-slot:[`item.vechile_brand`]="{ item }">
            <span v-if="item.vechile_brand">{{ item.vechile_brand.name }}</span>

            <span class="redColor fontBold" v-else>{{ $t("notFound") }}</span>
          </template>
          <!-- IsActive -->
          <template v-slot:[`item.is_active`]="{ item }">
            <span
              class="status"
              :class="item.is_active ? 'active' : 'inactive'"
            >
              {{ item.is_active ? $t("active") : $t("inActive") }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon
                class="edit"
                v-if="!canEdit"
                small
                @click="editItem(item)"
              >
                fal fa-edit
              </v-icon>
              <deleter
                v-if="!canDelete"
                :items="rows"
                name="vehicle_model"
                :id="item.id"
                @refresh="rows = $event"
              />
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <h3 class="table-title title">
              {{ $t("breadcrumb.vehicle_models.title") }}
              <span class="total">({{ total }})</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    far fa-info-circle
                  </v-icon>
                </template>
                <span> {{ $t("breadcrumb.vehicle_models.all") }}</span>
              </v-tooltip>
            </h3>
            <!-- Delete dialog -->

            <!-- Add & Delete -->

            <v-row>
              <v-col cols="12" sm="8"></v-col>
            </v-row>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
        <!-- Start Pagination -->
        <template>
          <div
            class="pagination_container text-center mb-5 d-flex justify-content-end"
          >
            <v-pagination
              color="primary"
              v-model="paginations.current_page"
              :length="paginations.last_page"
              :total-visible="5"
              @input="fetchData($event)"
            ></v-pagination>
          </div>
        </template>
        <!-- End Pagination -->
      </main>

      <!-- Start Image_Model -->
      <base-model
        @close="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            class="img-fluid"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // ======= Breadcrumb
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.vehicle_models.title"),
          disabled: false,
          href: "/vehicle_models/show-all",
        },
        {
          text: this.$t("breadcrumb.vehicle_models.all"),
          disabled: true,
          href: "",
        },
      ],
      // ========== headers
      headers: [
        {
          text: "#",
          align: "center",
          value: "index",
          sortable: false,
        },
        {
          text: this.$t("labels.mainImage"),
          align: "center",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("labels.name") + this.$t("inArabic"),
          value: "ar.name",
          align: "center",
        },
        {
          text: this.$t("labels.name") + this.$t("inEnglish"),
          value: "en.name",
          align: "center",
        },

        {
          text: this.$t("labels.vechile_brand"),
          value: "vechile_brand",
          align: "center",
        },
        // {
        //   text: "الحالة",
        //   value: "is_active",
        //   align: "center",
        // },
        {
          text: this.$t("labels.created_at"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      rows: [],
      search: null,
    };
  },
  watch: {
    [`$route`]() {
      if (this.$route.query.page) {
        this.paginations.current_page = +this.$route.query.page;
        this.setRows();
      } else {
        this.paginations.current_page = 1;
        this.setRows();
      }
    },
  },

  methods: {
    setRows() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: `vehicle_model`,
        params: { page: this.paginations.current_page },
      })
        .then((res) => {
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;
          this.total = res.data.meta?.total;
          this.rows = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err.request);
          this.loading = false;
          let message =
            err.response?.data.message ?? err.response?.data.messages;
          this.$iziToast.error({
            title: this.$t("validation.error"),
            message: message,
          });
        });
    },
    showItem(item) {
      this.$router.push({ path: `/vehicle_models/show/` + item.id });
    },
    editItem(item) {
      this.$router.push({ path: `/vehicle_models/edit/` + item.id });
    },

    imageError(index) {
      this.rows[
        index
      ].image = require("@/assets/media/images/Icons/no-data.png");
    },
    fetchData(e) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: e }),
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.setRows();
    },
  },

  created() {
    this.canEdit = this.$permission.allowed("vehicle_models/edit/");
    this.canAdd = this.$permission.allowed("vehicle_models/add");
    this.canDelete = this.$permission.allowed("vehicle_models/delete");
    this.canShow = this.$permission.allowed("vehicle_models/show/");
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },
};
</script>
