<template>
  <div class="homePage">
    <Loader v-if="loading" />
    <div v-else>
      <NoData v-if="noData" />
      <div class="" ref="main_wrapper" v-else>
        <div class="row fadeIn">
          <!-- End:: Aside -->
          <div class="col-12 py-0">
            <main>
              <div class="row">
                <div class="col-xxl-4" v-for="card in cards" :key="card.id">
                  <simple-card classes="w-100 ">
                    <statistics-card
                      :item="card"
                      :color="getRandomColor(colors)"
                    />
                  </simple-card>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartsAside from "@/components/Layouts/ChartsAside.vue";
import Loader from "@/components/Loaders/loader.vue";
import AreaCharts from "@/components/Charts/AreaCharts.vue";
import ColumnChart from "@/components/Charts/ColumnChart.vue";

export default {
  name: "HomePage",

  components: {
    ChartsAside,
    AreaCharts,
    ColumnChart,
    Loader,
  },

  data() {
    return {
      colors: [
        {
          first: "#62EAE1",
          second: "rgba(126, 229, 223, 0)",
        },
        {
          first: "#FF6370",
          second: "rgba(255, 99, 112, 0)",
        },
        {
          first: "#9E62EA",
          second: " #B199ED",
        },
        {
          first: "#EABC62",
          second: "#E9BA62",
        },
      ],
      total_orders: {
        id: 1,
        key: "total_orders",
        count: 49,
      },
      all_total_orders: {
        id: 1,
        key: "total_orders",
        count: 493,
      },
      pending_orders: {
        id: 1,
        key: "pending_orders",
        count: 22,
      },
      completed_orders: {
        id: 1,
        key: "completed_orders",
        count: 11,
      },
      canceled_orders: {
        id: 1,
        key: "canceled_orders",
        count: 5,
      },
      total_revenue: {
        id: 1,
        key: "total_revenue",
        count: "58952 SAR",
      },
      cart: {
        id: 1,
        key: "cart",
        count: 6,
      },
      withdrawal: {
        id: 1,
        key: "withdrawal",
        count: 4,
      },
      total_offers: {
        id: 1,
        key: "total_offers",
        count: 4,
      },
      total_flash_sale: {
        id: 1,
        key: "total_flash_sale",
        count: 4,
      },

      generalSummary: [
        {
          id: 1,
          key: "total_orders",
          name: "إجمالي المنتجات",
          count: 859,
          route: "/products/show-all",
        },

        {
          id: 2,
          key: "clients",
          name: "عدد العملاء",
          count: 5,
          route: "/client/show-all",
        },
        {
          id: 2,
          key: "clients",
          name: "عدد الأقسام",
          count: 11,
          route: "/categories/show-all",
        },
        {
          id: 2,
          key: "clients",
          name: "عدد الاسلايدر",
          count: 11,
          route: "/news-boards/show-all",
        },
        {
          id: 4,
          key: "total_orders",
          name: "التقيمات",
          count: 49,
          route: "/rates/show-all",
        },
        {
          id: 3,
          key: "completed_orders",
          name: "منتجات المخزون",
          count: 12,
          route: "/stock-tracking/show-all",
        },
      ],
      button_: {
        id: "",
      },
      // ========== Charts Aside
      asideIsActive: false,
      // start of main loader
      noData: true,
      loading: false,
      // end of main loader
      // ============ Charts Data
      cards: [],
      notifications_states: [],
      charts: [],
      order_status: null,
      recent_reviews: [],
      top_cards: [],
      recently_partner: [],
      recently_rider: [],
      top_vehicle_package: [],
      recent_orders: [],
      top_product: [],
      products_out_of_stock: [],
      transfer_requests: [],
      chartsData: null,
      revenue_chart: null,
      best_seller: null,

      top_recent_orders_headers: [
        {
          text: this.$t("labels.order_number"),
          value: "order_number",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.date"),
          value: "created_at",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.customer"),
          value: "client",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.price"),
          value: "total_price",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.quantity"),
          value: "total_quantity_product",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.is_payment"),
          value: "is_payment",
          align: "center",
          sortable: false,
        },

        {
          text: this.$t("labels.status"),
          value: `status_trans`,
          align: "center",
          sortable: true,
        },

        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      transfer_requests_headers: [
        {
          text: "#",
          value: "index",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("labels.rider"),
          value: "user_name",
          align: "center",
        },

        {
          text: this.$t("labels.amount"),
          value: "amount",
          align: "center",
        },
        {
          text: this.$t("labels.bank_name"),
          value: "bank_name",
          align: "center",
        },
        {
          text: this.$t("labels.account_number"),
          value: "account_number",
          align: "center",
        },
        {
          text: this.$t("labels.ibanNumber"),
          value: "iban",
          align: "center",
        },
        {
          text: this.$t("labels.created_at"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("labels.status"),
          value: "status",
          align: "center",
        },
        {
          text: this.$t("labels.accept_reject"),
          value: "accept_reject",
          align: "center",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth_module/currentUser"];
    },
  },

  methods: {
    toggleAside() {
      this.asideIsActive = !this.asideIsActive;
    },
    getRouteLink(item) {
      let link;
      if (item.notify_type == "new_trip") {
        link = "trips";
      } else if (item.notify_type == "new_rider") {
        link = "riders";
      } else if (item.notify_type == "new_partner") {
        link = "partners";
      } else if (item.notify_type == "finish_trip") {
        link = "trips";
      }

      return link;
    },
    getHome() {
      this.canShow = this.$permission.allowed("home/show");
      if (!this.canShow) {
        this.loading = true;
        this.noData = false;
        this.axios({
          method: "GET",
          url: "home",
        })
          .then((res) => {
            this.noData = false;
            this.cards = res.data.data;
            // this.notifications_states = res.data.data.notifications;
            // this.top_cards = res.data.data.top_selling_product_chart;
            // this.revenue_chart = res.data.data.revenue_chart;
            // this.best_seller = res.data.data.best_seller;

            // this.order_status = res.data.data.order_status;
            // this.recent_reviews = res.data.data.recent_reviews;
            // this.top_product = res.data.data.top_product;
            // this.products_out_of_stock = res.data.data.products_out_of_stock;
            // this.recent_orders = res.data.data.recent_orders;
            // this.transfer_requests = res.data.data.transfer_requests;

            this.loading = false;
          })
          .catch((err) => {
            this.noData = true;
            this.loading = false;
            this.$iziToast.error({
              displayMode: 2,
              title: this.$t("error"),
              message:
                err.response?.data.message ?? err.response?.data.messages,
            });
          });
      }
    },

    getRandomColor(colors) {
      let random = Math.floor(Math.random() * 4);

      return { colors: colors[random], id: random };
    },

    imageError(index, inp) {
      this[inp][
        index
      ].avatar = require("@/assets/media/images/Icons/no-data.png");
    },

    getPermissions() {
      this.axios.get("get_my_permissions").then((res) => {
        localStorage.setItem(
          "myTeam_Academy_Dash_permissions",
          JSON.stringify(res.data.data)
        );
        this.getHome();
        this.getSideBar();
      });
    },
    getControls() {
      this.axios
        .get("get_my_control_permissions")
        .then((res) =>
          localStorage.setItem("myTeam_controls", JSON.stringify(res.data.data))
        );
    },
    getSideBar() {
      this.axios.get("admin_side_bar").then((res) => {
        this.$router.push(`/${res.data[0].categories[0].permissions[0].url}`);
      });
    },
    changeStatus(item, status) {
      const frmData = new FormData();
      this.button_.id = item.id;

      frmData.append("_method", "PUT");
      frmData.append("status", status);
      // if (status == "rejected") {
      //   frmData.append("rejected_reason", data.rejected_reason);
      // }
      this.axios
        .post(`/wallet/withdraw/${item.id}`, frmData)
        .then(() => {
          this.button_.id = "";
          this.$iziToast.success({
            title: this.$t("success"),
            displayMode: 2,
            message:
              status == "accepted" ? "تمت الموافقة بنجاح" : "تمت رفض السحب ",
          });

          this.setRows();
        })
        .catch((err) => {
          this.button_.id = "";
          this.$iziToast.error({
            title: this.$t("error"),
            displayMode: 2,
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
  },

  created() {
    // if (this.user.usertype != "super_admin") {
    //   // this.getPermissions();
    //   this.getControls();
    // } else {
    //   if (localStorage.getItem("myTeam_controls")) {
    //     localStorage.removeItem("myTeam_controls");
    //   }
    //   if (localStorage.getItem("myTeam_Academy_Dash_permissions")) {
    //     localStorage.removeItem("myTeam_Academy_Dash_permissions");
    //   }
    //   this.getHome();
    // }
    if (this.user.usertype == "academy") {
      // this.getPermissions();
      // this.getControls();
      this.getHome();
    } else {
      if (localStorage.getItem("myTeam_Academy_Dash_permissions")) {
        localStorage.removeItem("myTeam_Academy_Dash_permissions");
      }
    }
  },
};
</script>

<style lang="scss">
.best_seller_card {
  position: relative;

  .avatar-img {
    position: absolute !important;
    inset-inline-end: 0.5rem !important;
    max-width: 120px;

    top: 0 !important;
    bottom: 0 !important;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 20px;
    color: #000;
    // padding: 10px 0;
  }

  p {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 17px;
    color: var(--mainText) !important;
    margin: 0;
  }
  a.btn {
    background: linear-gradient(
      97.46deg,
      #00a0e6 -1.53%,
      #00a0e6 132.35%
    ) !important;
    color: #fff !important;
    padding-block: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    opacity: 1;
    font-size: 14px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.homeTable {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    text-align: start !important;

    &:last-child {
      text-align: center !important;
    }
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    text-align: start !important;
  }

  .ordersLink {
    color: var(--main) !important;
    // font-size: 16px !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    text-align: center !important;
  }
}
.client_order {
  .title {
    display: block;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 15.5px !important;
    line-height: 17px;
    color: #666666;
    margin: 0;
    margin-bottom: 5px;
  }
  .email {
    font-size: 0.8rem !important;
    font-weight: 400;
    color: #2b3d2f !important;
    line-height: 1.375rem;
    letter-spacing: normal !important;
    text-transform: none !important;
  }
}
.stats_chart {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  // justify-content: space-c;
  padding: 15px;
  // flex-direction: row-reverse;
  .icon {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-end: 0.5rem;
  }
  .content {
    display: flex;
    flex-direction: column;
    .value {
      font-style: normal;
      font-weight: 500;
      // font-size: 16px;

      color: #000;
    }
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 4px;
      color: var(--mainText);
    }
    .values {
      margin-top: 0.5rem;
      span {
        color: var(--main);
        font-weight: 700;
        font-size: 1rem;
      }
    }
  }
}
.notifications_states {
  header {
    border-bottom: 1px solid #efefef !important;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 15px !important;
      line-height: 20px;
      color: #000;
    }
  }
  .content {
    min-height: 200px;
    max-height: 350px;
    overflow-y: auto;
    padding-block: 8px;
    .notification_card {
      .state {
        display: flex;
        align-items: center;
        .notification_content {
          margin-inline-start: 8px;
          .title {
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 17px;
            color: #000;
            margin: 0;
            margin-bottom: 5px;
          }
          p,
          a {
            font-style: normal;
            font-weight: 400 !important;
            font-size: 10px !important;
            line-height: 15px;
            color: var(--mainText);
            margin: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h4 {
          font-size: 18px !important;
        }
      }
    }
  }
  footer {
    margin-top: auto;
    text-align: center;
    border-top: 1px solid #efefef !important;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--mainText);
    }
  }

  &.client {
    .content {
      min-height: 250px;
      max-height: 250px;
    }
  }
}
.products_states {
  header {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px !important;
      line-height: 20px;
      color: #000;
      padding: 10px 0;
    }
  }
  .content {
    min-height: 350px !important;
    max-height: 350px !important;
    overflow-y: auto;
    padding-block: 8px;
    .product_card {
      border-bottom: 0.5px solid #e0e0eb;
      padding: 2px 0;
      .state {
        display: flex;
        align-items: center;
        // background: red !important;
        &:last-child {
          border-bottom: none !important;
        }

        .product_content {
          width: 100%;
          margin-inline-start: 8px;
          .title {
            display: block;
            font-style: normal;
            font-weight: 300 !important;
            font-size: 14px !important;
            line-height: 17px;
            color: #666666;
            margin: 0;
            margin-bottom: 5px;
          }
          p,
          a {
            font-style: normal;
            font-weight: 400 !important;
            font-size: 10px !important;
            line-height: 15px;
            color: var(--mainText);
            margin: 0;
          }
          .price {
            font-size: 0.9375rem !important;
            font-weight: 400;
            color: #2b3d2f !important;
            line-height: 1.375rem;
            letter-spacing: normal !important;
            text-transform: none !important;
          }
          .quantity {
            font-size: 0.8rem !important;
            font-weight: 400;
            color: #2b3d2f !important;
            line-height: 1.375rem;
            letter-spacing: normal !important;
            text-transform: none !important;
          }
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h4 {
          font-size: 18px !important;
        }
      }
    }
  }
  footer {
    text-align: center;
    border-top: 1px solid #efefef !important;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--mainText);
    }
  }

  &.client {
    .content {
      min-height: 250px;
      max-height: 250px;
    }
  }
}
// .cardBorder {
//   border: 1px solid var(--main);
// }
</style>
