<template>
  <div class="countries_all">
    <template>
      <Breadcrumb
        :canAddNew="!canAdd"
        :items="items"
        search_route="/flash-sale/add"
        :search_title="$t('breadcrumb.flashSales.add')"
        icon="fa-plus"
      />

      <!-- End Breadcrumb -->

      <!-- @filterFired="filterFired" -->
      <!-- :custom-filter="helper_filterSearch" -->

      <the-filter :inputs="inputs" />

      <main>
        <v-data-table
          class="thumb strip"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->

          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>

          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <template v-slot:[`item.is_active`]="{ item }">
            <span
              class="status"
              :class="item.is_active ? 'active' : 'inactive'"
            >
              <!-- {{ helper_boolToText(item.is_active) }} -->
              {{ item.is_active ? $t("active") : $t("inactive") }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon
                class="edit"
                v-if="!canEdit"
                small
                @click="editItem(item)"
              >
                fal fa-edit
              </v-icon>
              <deleter
                v-if="!canDelete"
                :items="rows"
                name="flash-sale"
                :id="item.id"
                @refresh="rows = $event"
              />
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <h3 class="table-title title">
              {{ $t("breadcrumb.flashSales.title") }}
              <span class="total">({{ total }})</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    far fa-info-circle
                  </v-icon>
                </template>
                <span> {{ $t("breadcrumb.flashSales.all") }}</span>
              </v-tooltip>
            </h3>
            <!-- Delete dialog -->

            <!-- Add & Delete -->

            <v-row>
              <v-col cols="12" sm="8"></v-col>
            </v-row>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
        <!-- Start Pagination -->
        <template>
          <div
            class="pagination_container text-center mb-5 d-flex justify-content-end"
          >
            <v-pagination
              color="primary"
              v-model="paginations.current_page"
              :length="paginations.last_page"
              :total-visible="5"
              @input="fetchData($event)"
            ></v-pagination>
          </div>
        </template>
        <!-- End Pagination -->
      </main>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.flashSales.title"),
          disabled: false,
          href: "/flash-sale/show-all",
        },
        {
          text: this.$t("breadcrumb.flashSales.all"),
          disabled: true,
          href: "",
        },
      ],

      inputs: [
        {
          keyName: "start_date",
        },
        {
          keyName: "end_date",
        },
      ],

      // ========== headers
      headers: [
        {
          text: this.$t("labels.start_at"),
          align: "center",
          value: "start_at",
          sortable: false,
        },
        {
          text: this.$t("labels.end_at"),
          align: "center",
          value: "end_at",
          sortable: false,
        },

        {
          text: this.$t("labels.status"),
          align: "center",
          value: "is_active",
          sortable: false,
        },
        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      rows: [],

      search: null,
    };
  },

  methods: {
    setRows() {
      this.loading = true;
      this.axios({
        method: "GET",
        url: `flash-sale`,
        params: {
          page: this.paginations.current_page,
          start_at: this.$route.query.start_date,
          end_at: this.$route.query.end_date,
        },
      })
        .then((res) => {
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;
          this.total = res.data.meta?.total;
          this.rows = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err.response.data.messages);
          let message =
            err.response?.data.message ?? err.response?.data.messages;
          this.$iziToast.error({
            title: this.$t("validation.error"),
            message: message,
          });
          this.loading = false;
        });
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: `/flash-sale/add` });
    },
    showItem(item) {
      this.$router.push({ path: `/flash-sale/show/` + item.id });
    },
    editItem(item) {
      this.$router.push({ path: `/flash-sale/edit/` + item.id });
    },

    fetchData(e) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: e }),
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.setRows();
    },
  },
  watch: {
    [`$route`]() {
      if (this.$route.query.page) {
        this.paginations.current_page = +this.$route.query.page;
        this.setRows();
      } else {
        this.paginations.current_page = 1;
        this.setRows();
      }
    },
  },
  created() {
    // console.log(this.canEdit);
    this.canEdit = this.$permission.allowed("flash-sale/edit");
    this.canDelete = this.$permission.allowed("flash-sale/delete");
    this.canAdd = this.$permission.allowed("flash-sale/add");
    this.canShow = this.$permission.allowed("flash-sale/show");
    this.canShowAll = this.$permission.allowed("flash-sale/show-all");
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    if (!this.canShowAll) {
      this.setRows();
    } else {
      this.$router.push({ path: `/permission-required` });
    }
  },
};
</script>
