import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      user: {
        id: localStorage.getItem("myTeam_Academy_Dash_User_Id"),
        is_accept_term: localStorage.getItem(
          "myTeam_Academy_Dash_Accept_Terms"
        ),
        token: localStorage.getItem("myTeam_Academy_Dash_Token"),
        usertype: localStorage.getItem("myTeam_Academy_Dash_User_Type"),
        name: localStorage.getItem("myTeam_Academy_Dash_User_name"),
        image: localStorage.getItem("myTeam_Academy_Dash_userImg"),
        phone: localStorage.getItem("myTeam_Academy_Dash_userPhone"),
        permissions: localStorage.getItem("myTeam_Academy_Dash_permissions"),
      },
    };
  },
  mutations,
  actions,
  getters,
};
