<template>
  <div :class="col ? `col-md-${col}` : ''">
    <!-- Start:: Select Input -->
    <div class="input_wrapper select" :class="classes">
      <div class="select-all-wrapper"></div>
      <v-autocomplete
        :disabled="disabled"
        :chips="multiple"
        :loading="itemsLoading"
        :deletable-chips="multiple"
        :small-chips="multiple"
        :multiple="multiple"
        :items="isStatic ? _staticItems : items"
        :item-text="itemText"
        item-value="id"
        :return-object="returnObject"
        :value="value"
        :label="placeholder"
        @blur="validateInput(value)"
        @input="updateValue($event)"
        @change="$emit('change', $event)"
        :required="required"
      >
        <template v-slot:prepend-item v-if="multiple">
          <v-list-item ripple @mousedown.prevent @click="selectAll">
            <v-list-item-action>
              <v-icon>
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> اختر الكل</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>
      <span class="spinnerIcon">
        <i v-if="isStatic && loading" class="fas fa-spinner"></i>
      </span>
      <transition name="fadeIn">
        <p class="validationErr" v-if="showAlert">{{ validationMessage }}</p>
      </transition>
    </div>
    <!-- End:: Select Input -->
  </div>
</template>

<script>
export default {
  name: "BaseSelectInput",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    staticItems: {
      required: false,
      type: Array,
    },
    classes: {
      required: false,
      type: String,
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: true,
    },
    static: {
      required: false,
      type: Boolean,
      default: false,
    },
    getUrl: {
      required: false,
      type: String,
    },
    value: {
      required: true,
    },
    placeholder: {
      required: true,
      type: String,
    },
    col: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      required: false,
      default: "name",
    },
  },

  data() {
    return {
      // Validation Alert
      showAlert: false,
      validationMessage: "errer",

      items: [],
      itemsLoading: false,
    };
  },

  computed: {
    _staticItems() {
      return this.staticItems;
    },

    isStatic() {
      return this.static;
    },

    icon() {
      if (this.allSelected) return "mdi-close-box";
      if (this.value?.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    allSelected() {
      if (!this.static) {
        if (this.returnObject) {
          return (
            this.value.length === this.items?.length &&
            this.items?.every((item) => this.value.includes(item))
          );
        } else {
          return (
            this.value.length === this.items.length &&
            this.items?.every((item) => this.value.includes(item.id))
          );
        }
      } else if (this.static) {
        if (this.returnObject) {
          return (
            this.value.length === this.staticItems?.length &&
            this.staticItems?.every((item) => this.value.includes(item))
          );
        } else {
          return (
            this.value.length === this.staticItems?.length &&
            this.staticItems?.every((item) => this.value.includes(item.id))
          );
        }
      }
    },
  },

  methods: {
    updateValue(e) {
      console.log("Updated Value:", e); // Debug log
      this.$emit("input", e);
    },

    validateInput(value) {
      if (this.required) {
        let valid = true;

        if (!value) {
          this.validationMessage = this.$t("validation.empty");
          valid = false;
        }

        this.showAlert = !valid;
      }
    },

    getSelectItems() {
      if (this.static) {
        // this.items = [...this._staticItems];
        return;
      }
      this.itemsLoading = true;
      this.axios({
        method: "GET",
        url: `${this.getUrl}`,
      })
        .then((res) => {
          this.items = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item[this.$i18n.locale]?.name
                ? item[this.$i18n.locale].name
                : item.full_name
                ? item.full_name
                : item.fullname
                ? item.fullname
                : item[this.$i18n.locale]?.title
                ? item[this.$i18n.locale].title
                : item.title ?? item[this.$i18n.locale]?.name
                ? item[this.$i18n.locale].name
                : item.name,
            };
          });
          this.itemsLoading = false;
        })
        .catch(() => {
          this.itemsLoading = false;
        });
    },

    selectAll() {
      this.$nextTick(() => {
        if (this.allSelected) {
          // Deselect all
          this.updateValue([]);
        } else if (!this.static) {
          // Select all items
          const allItems = this.items.map((item) =>
            this.returnObject ? item : item.id
          );
          this.updateValue(allItems);
        } else if (this.static) {
          // Select all items
          const allItems = this.staticItems.map((item) =>
            this.returnObject ? item : item.id
          );
          this.updateValue(allItems);
        }
      });
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.getSelectItems();
  },
};
</script>

<style scoped lang="scss"></style>
