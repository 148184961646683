<template>
  <div>
    <v-switch
      :disabled="loading || disabled"
      :loading="loading"
      :color="type == 'active' ? '#26BE00' : '#ed143d'"
      v-model="item[value == 'update-active' ? 'is_admin_active_user' : value]"
      @mousedown="confirmSwith(item, value, $event)"
    ></v-switch>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      required: false,
    },
    name: {
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
    };
  },
  methods: {
    // ===== Delete
    confirmSwith(item, value, event) {
      event.preventDefault();
      // Start:: Sweet Alert  Modal
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("youWillNotBeAbleToGoBackInTheProcess"),

        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.SwitchToggle(item, value);
        }
      });
      // End:: Sweet Alert  Modal
    },

    SwitchToggle(item, value) {
      this.loading = true;
      const frmData = new FormData();
      if (value == "is_active" || value == "is_admin_active_user") {
        frmData.append("_method", "PATCH");
      } else {
        frmData.append("_method", "PUT");
      }
      // is_admin_active_user

      frmData.append(
        value == "is_admin_active_user" ? "is_active" : value,
        +item[value] == 0 ? 1 : 0
      );

      let url = "";
      let message = "";

      if (value == "is_locked") {
        url = `${this.name}/${item.id}/update-locked`;
        message =
          item.is_locked == 1 ? "تم  الغاء حجب الحساب" : "تم  حجب الحساب";
      } else if (value == "is_active" && this.name != "groups") {
        url = `${this.name}/${item.id}/toggle-active-user`;
        message = item.is_active ? "تم الغاء تفعيل الحساب" : "تم  تفعيل الحساب";
      } else if (value == "is_active" && this.name == "groups") {
        url = `${this.name}/${item.id}/toggle-active`;
        message = item.is_active
          ? "تم الغاء تفعيل المجموعة"
          : "تم  تفعيل المجموعة";
      } else if (value == "is_ban") {
        url = `${this.name}/${item.id}/update-ban`;
        message = item.is_ban == 1 ? "تم  الغاء حظر الحساب" : "تم  حظر الحساب";
      } else if (value == "is_admin_active_user") {
        url = `${this.name}/${item.id}/toggle-active-user`;
        message = item.is_admin_active_user
          ? "تم الغاء تفعيل الحساب"
          : "تم  تفعيل الحساب";
      } else if (value == "update-active") {
        url = `${this.name}/${item.id}/update-active`;
        message = item.is_admin_active_user
          ? "تم  الغاء تفعيل الحساب"
          : "تم  تفعيل الحساب";
      }

      this.axios
        .post(url, frmData)
        .then(() => {
          item[value == "update-active" ? "is_admin_active_user" : value] =
            +item[value == "update-active" ? "is_admin_active_user" : value] ==
            1
              ? 0
              : 1;
          this.loading = false;
          this.$iziToast.success({
            title: this.$t("success"),
            message: message,
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
  },
};
</script>

<style></style>
