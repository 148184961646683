<template>
  <div>
    <Breadcrumb :items="items" />
    <Loader v-if="loading && editMode"></Loader>
    <div v-else>
      <noData v-if="noData" />
      <div class="form countryForm custom_card" v-else>
        <ValidationObserver ref="flashSalesForm" @submit.prevent="submit">
          <div class="row">
            <!-- <div class="col-md-12 d-flex align-items-center">
              <div class="input-title">
                <h4>{{ $t("labels.active") }}</h4>
              </div>
              <v-switch
                color="primary"
                value
                v-model="data.is_active"
                class="w-fit"
              ></v-switch>
            </div> -->
            <!-- Start:: Checks -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.is_active')"
              class="col-12"
              rules="required"
            >
              <div>
                <!-- <div class="input-title">
                  <h4>
                    {{ $t("labels.active", { name: $t("") }) }}
                  </h4>
                </div> -->

                <v-switch
                  color="primary"
                  value
                  v-model="data.is_active"
                  class="w-fit"
                  :label="$t('labels.is_active')"
                ></v-switch>
              </div>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: Checks -->

            <!-- Start::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at')"
              class="col-md-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at')"
                type="date"
                :allowedDate="new Date()"
                v-model="data.start_at"
                :placeholder="$t('labels.start_at')"
                @input="updateStartAt"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at_time')"
              class="col-md-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at_time')"
                type="time"
                v-model="data.start_time"
                :placeholder="$t('labels.start_at_time')"
                @input="updateStartAt"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  Start At -->

            <!-- Start::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at')"
              class="col-md-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at')"
                type="date"
                v-model="data.end_at"
                :allowedDate="data.start_at ? data.start_at : null"
                :placeholder="$t('labels.end_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at_time')"
              class="col-md-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at_time')"
                type="time"
                v-model="data.end_time"
                :placeholder="$t('labels.end_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  end At -->

            <!-- Select Products -->
            <template>
              <div
                class="col-md-12"
                v-for="(row, index) in data.products_rows"
                :key="row.id"
              >
                <div class="row border border-1 rounded p-3">
                  <div class="col-md-11">
                    <div class="border border-1 rounded">
                      <div class="col-12">
                        <base-select-input
                          v-model="row.product"
                          :static="true"
                          :staticItems="productsList"
                          :placeholder="$t('labels.product')"
                          @change="getProductDetails(index, row.product.id)"
                        ></base-select-input>
                      </div>

                      <div
                        v-if="row.product_details_show.length"
                        class="py-1 px-3"
                      >
                        <h5>{{ $t("labels.features") }}</h5>
                        <main>
                          <v-data-table
                            class="elevation-1 thumb diff_table"
                            :headers="headers"
                            :items="row.product_details_show"
                            disable-pagination
                            item-key="id"
                            :show-select="true"
                            :single-select="true"
                            v-model="row.selected"
                            hide-default-footer
                          >
                            <!-- ================== You Can use any slots you want ================== -->

                            <template v-slot:no-data>
                              {{ $t("table.noData", { name: "بروموكود" }) }}
                            </template>

                            <template v-slot:[`item.item`]="{ item }">
                              <div class="productRow mb-0 w-100">
                                <div class="detail">
                                  <div class="detail_show w-100">
                                    <span class="rate">
                                      {{ item.rate_avg }}
                                      <span><i class="fas fa-star"></i></span>
                                    </span>
                                    <div class="color w-50">
                                      <span class="key">
                                        {{ $t("labels.code") }}
                                      </span>

                                      <p class="value">
                                        {{ item.code ? item.code : "لا يوجد" }}
                                      </p>
                                    </div>
                                    <div class="image w-50">
                                      <span class="key">
                                        {{ $t("labels.image") }}
                                      </span>

                                      <img
                                        v-if="item.media"
                                        :src="item.media[0].image"
                                        alt=""
                                      />
                                      <img
                                        v-else
                                        :src="item.product.image"
                                        alt=""
                                      />
                                    </div>

                                    <div class="color w-50">
                                      <span class="key">
                                        {{ $t("labels.color") }}
                                      </span>
                                      <v-chip
                                        :color="
                                          item.color
                                            ? item.color.hex
                                            : 'لا يوجد'
                                        "
                                        class="value"
                                      ></v-chip>
                                    </div>

                                    <div class="size w-50">
                                      <span class="key">
                                        {{ $t("labels.quantity") }}
                                      </span>
                                      <v-chip
                                        color="#0E3958"
                                        class="value text-white"
                                      >
                                        {{
                                          item.quantity
                                            ? item.quantity
                                            : "لا يوجد"
                                        }}
                                      </v-chip>
                                    </div>

                                    <div class="size w-50">
                                      <div class="size">
                                        <span class="key">{{
                                          $t("labels.sizes")
                                        }}</span>
                                        <v-chip
                                          color="#0E3958"
                                          class="value text-white"
                                        >
                                          {{
                                            item.size
                                              ? item.size.name
                                              : "لا يوجد"
                                          }}
                                        </v-chip>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </v-data-table>
                        </main>
                      </div>
                    </div>
                    <div class="row">
                      <!-- start discount_type -->
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('labels.discount_type')"
                        class="col-lg-4"
                        rules="required"
                      >
                        <base-select-input
                          :disabled="!row.selected[0]"
                          :name="$t('labels.discount_type')"
                          v-model="row.discount_type"
                          :static="true"
                          :staticItems="discountTypes"
                          :placeholder="$t('labels.discount_type')"
                          @change="handleDiscountChange(row, index)"
                        ></base-select-input>
                        <span
                          v-if="errors[0]"
                          class="error--text d-inline-block"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                      <!-- end discount_type -->

                      <!-- start discount_amount -->
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('labels.discount_amount')"
                        class="col-lg-4"
                        rules="required"
                      >
                        <!-- :disabled="!row.selected[0]" -->
                        <base-input
                          :disabled="!row.discount_type"
                          :name="$t('labels.discount_amount')"
                          v-model="row.discount_amount"
                          type="number"
                          @keypress="preventMinus"
                          :placeholder="
                            row.discount_type &&
                            row.discount_type.id === 'percentage'
                              ? $t('labels.discount_percent')
                              : $t('labels.discount_amount')
                          "
                        />
                        <span
                          v-if="errors[0]"
                          class="error--text d-inline-block"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                      <!-- end discount_amount -->

                      <!-- start price_before -->
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('labels.price_before_discount')"
                        class="col-lg-4"
                        rules=""
                        v-if="row.selected.length"
                      >
                        <base-input
                          disabled
                          :name="$t('labels.price_before_discount')"
                          v-model="row.selected[0].price"
                          type="number"
                          @keypress="preventMinus"
                          :placeholder="$t('labels.price_before_discount')"
                        />
                        <span
                          v-if="errors[0]"
                          class="error--text d-inline-block"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                      <!-- end price_before -->

                      <!-- start price_after -->
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('labels.price_after_discount')"
                        class="col-lg-4"
                        rules=""
                        v-if="row.selected.length"
                      >
                        <!-- v-model="row.price_after" -->
                        <base-input
                          disabled
                          :name="$t('labels.price_after_discount')"
                          v-model="calculatedPriceAfter[index]"
                          type="number"
                          @keypress="preventMinus"
                          :placeholder="$t('labels.price_after_discount')"
                        />
                        <span
                          v-if="errors[0]"
                          class="error--text d-inline-block"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                      <!-- end price_after -->

                      <!-- start quantity -->
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('labels.quantity')"
                        class="col-lg-4"
                        rules="required"
                      >
                        <base-input
                          :disabled="!row.selected[0]"
                          :name="$t('labels.quantity')"
                          v-model="row.quantity"
                          type="number"
                          @keypress="preventMinus"
                          :placeholder="$t('labels.quantity_total')"
                        />
                        <span
                          v-if="errors[0]"
                          class="error--text d-inline-block"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                      <!-- end quantity -->

                      <!-- start quantity_for_user -->
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('labels.quantity_for_user')"
                        class="col-lg-4"
                        rules="required"
                      >
                        <base-input
                          :disabled="!row.selected[0]"
                          :name="$t('labels.quantity_for_user')"
                          v-model="row.quantity_for_user"
                          type="number"
                          @keypress="preventMinus"
                          :placeholder="$t('labels.quantity_for_user')"
                        />
                        <span
                          v-if="errors[0]"
                          class="error--text d-inline-block"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                      <!-- end quantity_for_user -->

                      <!-- start ordering -->
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('labels.ordering')"
                        class="col-lg-4"
                        rules="required"
                      >
                        <base-input
                          :disabled="!row.selected[0]"
                          :name="$t('labels.ordering')"
                          v-model="row.ordering"
                          type="number"
                          @keypress="preventMinus"
                          :placeholder="$t('labels.ordering')"
                        />
                        <span
                          v-if="errors[0]"
                          class="error--text d-inline-block"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                      <!-- end ordering -->
                    </div>
                  </div>

                  <div class="col-md-1 py-0 d-flex justify-center align-center">
                    <span
                      v-if="index == data.products_rows.length - 1"
                      class="append mx-1"
                      style="cursor: pointer"
                      @click="appendProductRow"
                    >
                      <i class="fas fa-plus-circle"></i>
                    </span>

                    <span
                      v-if="data.products_rows.length > 1"
                      class="append mx-1"
                      style="cursor: pointer"
                      @click="deleteProductRow(index)"
                    >
                      <i class="fas fa-minus-circle"></i>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div class="button_wrapper center">
            <v-btn
              type="submit"
              @click="submit"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t("submit") }}
            </v-btn>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      isStartAtUpdated: false,

      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.flashSales.title"),
          disabled: false,
          href: "/flash-sale/show-all",
        },
        {
          text: this.$t(`breadcrumb.flashSales.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      loading: false,
      headers: [
        {
          text: this.$t("labels.product"),
          align: "center",
          value: "item",
          sortable: true,
        },
      ],
      data: {
        price: null,

        end_time: null,
        start_time: null,

        start_at: null,
        end_at: null,
        is_active: false,

        products_rows: [
          {
            product: null,
            product_details_show: [],
            // product_details_ids: [],
            selected: [],
            discount_amount: null,
            discount_type: null,
            price_before: null,
            price_after: null,
            quantity: null,
            quantity_for_user: null,
            ordering: null,
          },
        ],
      },

      productsList: [],

      discountTypes: [
        {
          id: "value",
          name: this.$t("monyAmount"),
        },
        {
          id: "percentage",
          name: this.$t("percentage"),
        },
      ],
    };
  },
  methods: {
    handleDiscountChange(row, index) {
      // Update price_after based on calculatedPriceAfter
      this.$set(
        this.data.products_rows[index],
        "price_after",
        this.calculatedPriceAfter[index]
      );
    },

    async getListOfProducts() {
      let url = "product/without_paginate";

      await this.axios.get(url).then((res) => {
        this.productsList = res.data.data;
      });
    },

    getProductDetails(index, id) {
      this.axios({
        method: "GET",
        url: `product-details-v2/${id}`,
      }).then((res) => {
        // this.data.products_rows[index].product_details_ids = [];
        this.data.products_rows[index].product_details_show =
          res.data.data.product_details;
      });
    },

    //Products
    appendProductRow() {
      this.data.products_rows.push({
        product: null,
        product_details_show: [],
        // product_details_ids: [],
        selected: [],
        discount_amount: null,
        discount_type: null,
        price_after: null,
        quantity: null,
        quantity_for_user: null,
        ordering: null,
      });
    },
    deleteProductRow(index) {
      this.data.products_rows.splice(index, 1);
    },

    getEditData() {
      this.noData = false;
      this.loading = true;
      this.axios({
        method: "GET",
        url: `flash-sale-details/${this.id}`,
      })
        .then((res) => {
          this.loading = false;
          const result = res.data.data;

          this.data.start_time = result.start_time;
          this.data.end_time = result.end_time;

          this.data.is_active = result.is_active;
          this.data.start_at = new Date(result.start_at).toLocaleDateString(
            "en-CA"
          );
          this.data.end_at = new Date(result.end_at).toLocaleDateString(
            "en-CA"
          );

          // Products
          this.data.products_rows = [];
          result.flash_sale_products &&
            result.flash_sale_products.forEach((el) => {
              const selectedProduct = el.product_details_show.find(
                (selected) => selected.id === el.product_details_id
              );
              this.data.products_rows.push({
                product: el.product,
                product_details_show: el.product_details_show,
                selected: [selectedProduct], // Set the selected property based on the product details id
                discount_amount: el.discount_amount,
                price_before: el.price_before,
                price_after: el.price_after,
                quantity: el.quantity,
                quantity_for_user: el.quantity_for_user,
                ordering: el.ordering,
                discount_type: {
                  id: el.discount_type_details.id,
                  name: el.discount_type_details.name,
                },
              });
            });
        })
        .catch((err) => {
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
          this.noData = true;
          this.loading = false;
        });
    },

    updateStartAt() {
      this.isStartAtUpdated = true;
    },

    submit() {
      this.$refs.flashSalesForm.validate().then((d) => {
        if (d) {
          this.loading = true;
          const data = new FormData();

          data.append("is_active", +this.data.is_active);

          if (this.isStartAtUpdated) {
            data.append(
              "start_at",
              `${this.$moment(this.data.start_at).format("YYYY-MM-DD")} ${
                this.data.start_time
              }`
            );
          }

          data.append(
            "end_at",
            `${this.$moment(this.data.end_at).format("YYYY-MM-DD")} ${
              this.data.end_time
            }`
          );

          // Append Products
          this.data.products_rows &&
            this.data.products_rows.forEach((row, index) => {
              row.selected.forEach((el) => {
                data.append(
                  `flash_sale_products[${index}][product_detail_id]`,
                  el.id
                );
              });

              data.append(
                `flash_sale_products[${index}][quantity]`,
                row.quantity
              );
              data.append(
                `flash_sale_products[${index}][quantity_for_user]`,
                row.quantity_for_user
              );
              data.append(
                `flash_sale_products[${index}][ordering]`,
                row.ordering
              );

              //   if (row.discount_type.id) {
              data.append(
                `flash_sale_products[${index}][discount_type]`,
                row.discount_type.id
              );
              //   }
              data.append(
                `flash_sale_products[${index}][discount_amount]`,
                row.discount_amount
              );
              // data.append(
              //   `flash_sale_products[${index}][price_before]`,
              //   row.price_before
              // );
              // data.append(
              //   `flash_sale_products[${index}][price_after]`,
              //   row.price_after
              // );

              if (this.id) {
                data.append(
                  `flash_sale_products[${index}][id]`,
                  row.id ? row.id : ""
                );
              }
            });

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `flash-sale/${this.id}`;
          } else {
            url = "flash-sale";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.loading = false;
              this.$router.push("/flash-sale/show-all");
            })
            .catch((err) => {
              this.loading = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message:
                  err.response.data.message ?? err.response.data.messages,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
  },

  computed: {
    calculatedPriceAfter() {
      return this.data.products_rows.map((row) => {
        // If discount_type is 'value', calculate price_after as difference between price_before and discount_amount
        if (row.discount_type && row.discount_type.id === "value") {
          return row.selected[0].price - row.discount_amount;
        }
        // If discount_type is 'percentage', calculate price_after as price_before multiplied by (1 - discount_amount / 100)
        else if (row.discount_type && row.discount_type.id === "percentage") {
          return row.selected[0].price * (1 - row.discount_amount / 100);
        }
        // If discount_type is not set or invalid, return null
        else {
          return null;
        }
      });
    },

    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.canAdd = this.$permission.allowed("flash-sale/add");
    this.canEdit = this.$permission.allowed("flash-sale/edit");
    this.canShow = this.$permission.allowed("flash-sale/show");
    if (this.id) {
      if (!this.canEdit) {
        if (!this.canShow) {
          this.getEditData();
        }
      } else {
        this.$router.push({ path: `/permission-required` });
      }
    } else if (this.canAdd) {
      this.$router.push({ path: `/permission-required` });
    }
    this.getListOfProducts();
  },
};
</script>
<style lang="scss">
.p-15 {
  padding: 6px;
}
.productRow {
  // border: 1px solid #00a0e6;
  border-radius: 15px;
  // padding: 10px 10px 20px 10px;
  margin-bottom: 20px;

  .detail {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .detail_show {
      // border: 1px solid #00a0e6;
      // padding: 10px;
      flex-grow: 1;
      border-radius: 15px;
      position: relative;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .rate {
        color: gray;
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 13px;

        span {
          color: yellow;
        }
      }

      .image {
        img {
          max-height: 50px;
        }
      }

      .color,
      .size {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .key {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
