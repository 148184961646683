<template>
  <div class="data_card">
    <div class="main_data_card">
      <div class="text_content">
        <h5>{{ title }}</h5>
        <span>{{ desc }}</span>
      </div>
      <div class="data_icon">
        <i :class="`fas fa-${icon}`"></i>
      </div>
    </div>
    <div class="font_icon">
      <i :class="`fas fa-long-arrow-alt-right`"></i>
    </div>
    <!-- <div class="image">
      <img src="@/assets/media/images/Icons/img-status-5.svg" />
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["desc", "icon", "title"],
};
</script>

<style lang="scss">
.data_card {
  position: relative;
  box-shadow: 0 14px 54px 0, #00000008;
  padding: 20px 15px;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid var(--main);
  overflow: hidden;
  .image {
    position: absolute;
    right: 0px;
    bottom: -35px;
  }

  .main_data_card {
    @include flexSpaceBetweenAlignment;
    margin-bottom: 20px;
    .data_icon {
      i {
        font-size: 60px;
        opacity: 0.4;
      }
    }
  }
  .font_icon {
    @include flexEndAlignment;
  }
  .text_content {
    h5 {
      font-size: 1.2em;
    }
    span {
      font-size: 0.8em;
    }
  }
}
</style>
