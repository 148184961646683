var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat_custom_card"},[_c('div',{staticClass:"chatCom"},[_c('div',{staticClass:"chat_box"},[_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({ smooth: true }),expression:"{ smooth: true }"}],staticClass:"chat_view"},_vm._l((_vm.messages),function(message){return _c('div',{key:message.id,staticClass:"messageOwner sender",class:{
            sender: message.sender?.user_type == 'didd',
            receiver: message.sender?.user_type == 'didd',
          }},[_c('img',{staticClass:"user_img",attrs:{"src":message.sender?.image}}),_c('div',{staticClass:"content"},[_c('span',{staticClass:"created_at"},[_vm._v(" "+_vm._s(message.sender?.full_name ? message.sender?.full_name + ` (${message.sender?.user_type})` : "مستخدم محذوف")+" ")]),(
                message.message_type == 'image' ||
                message.message_type == 'video' ||
                message.message_type == 'audio'
              )?_c('div',{staticClass:"media"},[(message.message_type == 'image')?_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":message.message}}),(message.descreption)?_c('p',{staticClass:"descreption"},[_vm._v(" "+_vm._s(message.descreption)+" ")]):_vm._e()]):_vm._e(),(message.message_type == 'video')?_c('div',{staticClass:"video"},[_c('video',{attrs:{"controls":""}},[_c('source',{attrs:{"src":message.message,"type":"video/mp4"}})]),(message.descreption)?_c('p',{staticClass:"descreption"},[_vm._v(" "+_vm._s(message.descreption)+" ")]):_vm._e()]):_vm._e(),(message.audio_src)?_c('div',{staticClass:"audio_media"},[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":message.audio_src,"type":"audio/ogg"}})])]):_vm._e()]):_vm._e(),(
                message.message_type == 'text' ||
                message.message_type == 'offer'
              )?_c('p',{staticClass:"text",domProps:{"innerHTML":_vm._s(message.message.replace(/(\n|\n|\r)/gm, '<br />'))}}):_vm._e(),_c('span',{staticClass:"created_at"},[_vm._v(" "+_vm._s(new Date(message.created_at).toLocaleString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric", }))+" ")])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }