<template>
  <div :class="col ? `col-md-${col}` : ''">
    <div class="flagged_phone_content_wrapper">
      <!-- Start:: Phone Input -->
      <div class="input_wrapper">
        <!-- <v-text-field
          type="number"
          @keypress="preventMinus"
          :label="placeholder"
          :value="value"
          @blur="validateInput(value)"
          @input="updateValue($event)"
          :required="required"
        ></v-text-field> -->
        <div class="autocomplete">
          <input
            type="text"
            :value="value"
            @input="onInput"
            @focus="showDropdown = true"
            @blur="onBlur"
            :placeholder="placeholder"
          />
          <ul v-if="showDropdown && filteredItems.length" class="dropdown">
            <li
              v-for="(item, index) in filteredItems"
              :key="index"
              @mousedown.prevent="selectItem(item)"
            >
              {{ item.full_name + `(${item.phone})` }}
            </li>
          </ul>
        </div>
        <!-- <v-autocomplete
          chips
          deletable-chips
          small-chips
          item-text="name"
          item-value="id"
          :return-object="true"
          @input="updateValue($event)"
          :loading="isLoading"
          :search-input.sync="search"
          :items="items"
          :label="placeholder"
          :auto-select-first="false"
          :value="value"
          :required="required"
          @update:search-input="onSearchInput"
          ><template v-slot:no-data>
            <div>{{ $t("noDataFound") }}</div>
          </template></v-autocomplete
        > -->
        <!-- <transition name="fadeIn">
          <p class="validationErr" v-if="showAlert">{{ validationMessage }}</p>
        </transition> -->
      </div>
      <!-- End:: Phone Input -->
      <!-- Start:: Flag & Country Key -->
      <div class="position-relative">
        <!-- ********** Start:: Selected Flag & Key ********** -->
        <button
          type="button"
          class="selected_flag_wrapper"
          @click.stop="toggleCountriesKeysMenu"
          v-if="selectedCountry"
        >
          <span class="selected_country_key">
            {{ selectedCountry.key }}
          </span>
          <img
            class="selected_country_flg"
            :src="selectedCountry.flag"
            alt=""
            width="30"
            height="22"
          />
        </button>
        <!-- ********** End:: Selected Flag & Key ********** -->

        <!-- ********** Start:: Flags & Keys List ********** -->
        <transition name="fadeInUp">
          <ul class="keys_and_flags_list" v-if="countriesKeysMenuIsOpen">
            <li
              v-for="item in countriesKeys"
              :key="item.id"
              @click.stop="selectKey(item)"
              class="list_item"
            >
              <span class="country_key">
                {{ item.key }}
              </span>
              <img
                class="country_flg"
                :src="item.flag"
                alt=""
                width="30"
                height="22"
              />
            </li>
          </ul>
        </transition>
        <!-- ********** End:: Flags & Keys List ********** -->
      </div>
      <!-- End:: Flag & Country Key -->
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCountryFlagPhoneInput",

  props: {
    // ====== General Inputs Props
    url: {
      required: true,
      type: String,
      default: "",
    },
    value: {
      required: true,
    },
    placeholder: {
      required: true,
      type: String,
    },
    col: {
      required: false,
      type: String,
    },
    preSelectedPhoneCode: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    only: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Validation Alert
      showAlert: false,
      validationMessage: "errer",

      countriesKeys: [],

      isLoading: false,
      search: "",

      searchQuery: "",
      showDropdown: false,
      filteredItems: [],

      items: [],

      // Start:: control Countries Keys Menu Data
      countriesKeysMenuIsOpen: false,
      // End:: control Countries Keys Menu Data

      // Start:: Selected Country Data
      selectedCountry: null,
      // End:: Selected Country Data

      // Start:: Front End Input Validation
      frontEndInputValidation: {
        inputValue: [(val) => (val || "").length > 0 || this.validationHint],
      },
      // End:: Front End Input Validation
    };
  },

  methods: {
    // Start:: Update Input Value
    updateValue(e) {
      this.$emit("input", e);
    },
    // End:: Update Input Value
    // onInput(event) {
    //   this.$emit("input", event.target.value);
    // },
    onInput(event) {
      this.showDropdown = true;
      this.$emit("input", event.target.value);
      // this.$router.push({
      //   query: Object.assign({}, this.$route.query, {
      //     page: 1,
      //     phone_search: this.value || "",
      //   }),
      // });
    },
    onBlur() {
      setTimeout(() => {
        this.showDropdown = false;
      }, 200);
    },
    selectItem(item) {
      this.searchQuery = item.phone;
      this.showDropdown = false;
      this.$emit("select", item);
    },

    onSearchInput(value) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: 1,
          phone_search: value || "",
        }),
      });
    },

    // Start:: Toggle Countries Keys Menu
    toggleCountriesKeysMenu() {
      this.countriesKeysMenuIsOpen = !this.countriesKeysMenuIsOpen;
    },
    // End:: Toggle Countries Keys Menu

    // Start:: Update Selected Country Key Value
    updateSelectedCountryKeyValue() {
      this.$emit("changeKey", {
        key: this.selectedCountry.key,
        limit: this.selectedCountry.limit,
      });
    },
    // End:: Update Selected Country Key Value

    // Start:: Select Country Key
    selectKey(country) {
      this.selectedCountry = country;
      this.updateSelectedCountryKeyValue();
      this.toggleCountriesKeysMenu();
    },
    // End:: Select Country Key

    validateInput(value) {
      let valid = true;

      if (!value) {
        this.validationMessage = this.$t("validation.empty");
        valid = false;
      }

      this.showAlert = !valid;
    },

    getData() {
      this.isLoading = true;
      this.axios({
        method: "GET",
        url: this.url,
        // params: { phone: newVal },
      })
        .then((res) => {
          this.isLoading = false;
          this.items = res.data.data.map((el) => {
            return {
              id: el.id,
              name: el.full_name + `(${el.phone})`,
              full_name: el.full_name,
              phone: el.phone,
              parents: el.parents ? el.parents : null,
            };
          });
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
          this.items = [];
        });
    },

    getCountryeKeys() {
      this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_BASE_URL_GENERAL}countries`,
      })
        .then((res) => {
          if (this.only) {
            this.countriesKeys = res.data.data
              .filter((opt) => opt.phone_code == "971")
              .map((item) => {
                return {
                  id: item.id,
                  flag: item.image,
                  key: item.phone_code,
                  limit: item.phone_number_limit,
                };
              });
          } else {
            this.countriesKeys = res.data.data.map((item) => {
              return {
                id: item.id,
                flag: item.image,
                key: item.phone_code,
                limit: item.phone_number_limit,
              };
            });
          }

          // ********** Start:: Set Initial Selected Key **********

          if (this.preSelectedPhoneCode) {
            this.selectedCountry = this.countriesKeys.find(
              (country) => country.key == this.preSelectedPhoneCode
            );
          } else {
            this.selectedCountry = this.countriesKeys[0];
          }
          this.updateSelectedCountryKeyValue();
          // ********** End:: Set Initial Selected Key **********
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    // search(newVal) {
    //   console.log(newVal);
    //   this.isLoading = true;
    //   this.axios({
    //     method: "GET",
    //     url: this.url,
    //     params: { phone: newVal },
    //   })
    //     .then((res) => {
    //       this.isLoading = false;
    //       this.items = res.data.data.map((el) => {
    //         return {
    //           id: el.id,
    //           name: el.full_name + `(${el.phone})`,
    //           full_name: el.full_name,
    //           phone: el.phone,
    //         };
    //       });
    //     })
    //     .catch((err) => {
    //       this.isLoading = false;
    //       console.log(err);
    //       this.items = [];
    //     });
    // },

    value(newQuery) {
      this.filteredItems = this.items.filter((item) => item.phone == newQuery);
    },
  },

  mounted() {},

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    // Start:: Fire Methods
    this.getCountryeKeys();
    window.addEventListener("click", () => {
      this.countriesKeysMenuIsOpen = false;
    });
    if (this.url) {
      this.getData();
    }
    // End:: Fire Methods
  },
};
</script>

<style lang="scss">
.autocomplete {
  position: relative;
  width: 100%;
  margin: 0;
  @include flexStartAlignment;
  transition: all 0.4s ease-in-out;
  border-radius: 10px;
  height: 50px;
  input {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
    font-size: 15px;
    background: transparent !important;
    border: 1px solid #dfdee1;
    &:focus {
      border-color: var(--main);
    }
    &:focus-visible {
      outline: 0;
    }
  }
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #dfdee1;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .dropdown li {
    padding: 10px 8px;
    cursor: pointer;
    font-size: 16px;
  }

  .dropdown li:hover {
    background-color: #f0f0f0;
  }
}
</style>
