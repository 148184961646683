<template>
  <div class="h-100 d-flex justify-content-center no-data">
    <img
      class="img-fluid"
      max-width="600"
      src="@/assets/media/images/Icons/no-data.png"
      alt=""
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.no-data {
  img {
    max-width: 800px;
  }
}
</style>
