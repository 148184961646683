<template>
  <div class="loader_gif">
    <div class="image_loader">
      <img
        src="../../assets/media/images/Icons/loader.svg"
        alt=""
        width="50"
        height="50"
      />
      <!-- <img
        v-if="current_theme == 'dark'"
        src="../../assets/media/images/logo/logo_light.svg"
        alt=""
      /> -->
    </div>
    <span></span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      current_theme: ["theme_module/current_theme"],
    }),
  },
};
</script>

<style lang="scss" scoped>
.loader_gif {
  height: calc(100vh - 55px);
}
@keyframes loader {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.1);
  }
}

.image_loader {
  height: 600px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    animation: loader 1s ease-in-out alternate infinite;
    width: auto !important;
    cursor: auto !important;
  }
}
</style>
