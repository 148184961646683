<template>
  <div>
    <Breadcrumb :items="items" />
    <Loader v-if="loading && editMode"></Loader>
    <div v-else>
      <noData v-if="noData" />
      <div class="form countryForm custom_card" v-else>
        <ValidationObserver ref="formCoupon" @submit.prevent="submit">
          <div class="row">
            <div class="col-md-12 d-flex align-items-center">
              <div class="input-title">
                <h4>{{ $t("active") }}</h4>
              </div>
              <v-switch
                color="primary"
                value
                v-model="data.is_active"
                class="w-fit"
              ></v-switch>
            </div>

            <div class="col-md-6 gap-3 mb-7">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.couponImageAr')"
                :rules="editMode ? '' : 'required'"
                class=""
                style="width: 100%"
              >
                <div class="input-title mb-3">
                  <h4>{{ $t("labels.couponImageAr") }}</h4>
                </div>
                <!-- @change="sentImage($event)" -->
                <v-file-input
                  @change="sentImage($event)"
                  v-model="data.avatar.mainMedia"
                  class="d-none"
                  :name="$t('labels.couponImageAr')"
                  accept="image/*,|size:2048"
                  label=""
                  id="banner-create-image-main-ar"
                />

                <label
                  :class="errors[0] ? 'errors' : ''"
                  for="banner-create-image-main-ar"
                  class="upload-image-label d-block d-flex justify-content-center align-items-center text-center border rounded-xl overflow-hidden"
                >
                  <v-img
                    v-if="data.avatar.preview"
                    class="d-flex"
                    cover
                    :src="data.avatar.preview"
                    :aspect-ratio="3.4"
                  />
                  <img
                    v-else
                    class="d-flex img-fluid mx-auto"
                    src="@/assets/media/images/placeholder.png"
                  />

                  <span class="error--text d-block">
                    {{ errors[0] }}
                  </span>
                </label>
              </validation-provider>
            </div>
            <div class="col-md-6"></div>
            <!-- Start:: code -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.code')"
              class="col-md-4"
              rules="required|min:2|max:250"
            >
              <base-input
                :name="$t('labels.code')"
                type="text"
                :placeholder="$t('labels.code')"
                v-model="data.code"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: code -->
            <!-- Start:: UsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.usage_times')"
              class="col-md-4"
              rules="required|min_value:1"
            >
              <base-input
                :name="$t('labels.usage_times')"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.usage_times')"
                v-model.number="data.usage_count"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UsageTimes -->

            <!-- Start:: UserUsageTimes -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.user_usage_count')"
              class="col-md-4"
              :rules="`required|min_value:1|${
                data.usage_count ? `max_value:${data.usage_count}` : ''
              }`"
            >
              <base-input
                :disabled="!data.usage_count"
                :name="$t('labels.user_usage_count')"
                type="number"
                @keypress="preventMinus"
                :placeholder="
                  !data.usage_count
                    ? $t('select') +
                      ' ' +
                      $t('labels.usage_times') +
                      ' ' +
                      $t('firstly')
                    : $t('labels.user_usage_count')
                "
                v-model.number="data.user_usage_count"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: UserUsageTimes -->
            <!-- Start:: discount_type -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.discount_type')"
              class="col-md-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.discount_type')"
                v-model="data.discount_type"
                :static="true"
                :staticItems="discountTypes"
                :placeholder="$t('labels.discount_type')"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: discount_type -->

            <!-- Start:: DiscountAmount -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="`${$t('discound')}`"
              class="col-md-4"
              :rules="`required|min_value:1|${
                data.discount_type?.id == 'percentage' ? 'max_value:100' : ''
              }`"
            >
              <base-input
                :name="`${$t('discound')}`"
                type="number"
                @keypress="preventMinus"
                :placeholder="`${$t('discound')}`"
                v-model.trim="data.discount_amount"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: DiscountAmount -->
            <!-- addition_option Start-->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.addition_options')"
              class="col-md-4"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.addition_options')"
                v-model="data.addition_option"
                :static="true"
                :staticItems="addition_options"
                :placeholder="$t('labels.addition_options')"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- addition_option End -->
            <!-- Start::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at')"
              class="col-md-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at')"
                type="date"
                :allowedDate="new Date()"
                v-model="data.start_at"
                :placeholder="$t('labels.start_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <!-- End::  Start At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.start_at_time')"
              class="col-md-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.start_at_time')"
                type="time"
                v-model="data.start_time"
                :placeholder="$t('labels.start_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  Start At -->
            <!-- Start::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at')"
              class="col-md-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at')"
                type="date"
                v-model="data.end_at"
                :allowedDate="data.start_at ? data.start_at : null"
                :placeholder="$t('labels.end_at')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  end At -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.end_at_time')"
              class="col-md-6"
              rules="required"
            >
              <base-picker-input
                :name="$t('labels.end_at_time')"
                type="time"
                v-model="data.end_time"
                :placeholder="$t('labels.end_at_time')"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End::  end At -->

            <!-- aplly on types -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.applly_coupon_on')"
              class="col-md-6"
              rules="required|min:2|max:250"
            >
              <base-select-input
                :name="$t('labels.applly_coupon_on')"
                v-model="data.applly_coupon_on"
                :static="true"
                :staticItems="applly_coupon_on_types"
                :placeholder="$t('labels.applly_coupon_on')"
                @change="getListOfProductsAndCaterogries($event.id)"
              ></base-select-input>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- Select Products -->
            <template
              v-if="
                data.applly_coupon_on.id == 'special_products' ||
                data.applly_coupon_on.id == 'except_products'
              "
            >
              <div
                class="col-md-12"
                v-for="(row, index) in data.products_rows"
                :key="row.id"
              >
                <div class="row border border-1 rounded">
                  <div class="col-md-11">
                    <div class="col-md-6">
                      <base-select-input
                        v-model="row.product"
                        :static="true"
                        :staticItems="prods_and_categories"
                        :placeholder="$t('labels.product')"
                        @change="getProductDetails(index, row.product.id)"
                      ></base-select-input>
                    </div>

                    <div v-if="row.product_details_show.length" class="py-1">
                      <h5>{{ $t("labels.features") }}</h5>
                      <main>
                        <v-data-table
                          class="elevation-1 thumb diff_table"
                          :headers="headers"
                          :items="row.product_details_show"
                          disable-pagination
                          item-key="id"
                          :show-select="true"
                          v-model="row.selected"
                          hide-default-footer
                        >
                          <!-- ================== You Can use any slots you want ================== -->

                          <template v-slot:no-data>
                            {{ $t("table.noData", { name: "بروموكود" }) }}
                          </template>

                          <template v-slot:[`item.item`]="{ item }">
                            <div class="productRow mb-0 w-100">
                              <div class="detail">
                                <div class="detail_show w-100">
                                  <span class="rate">
                                    {{ item.rate_avg }}
                                    <span><i class="fas fa-star"></i></span>
                                  </span>
                                  <div class="color">
                                    <span class="key">{{
                                      $t("labels.code")
                                    }}</span>

                                    <p
                                      :code="item.code ? item.code : 'لا يوجد'"
                                      class="value"
                                    ></p>
                                  </div>
                                  <div class="image">
                                    <span class="key">{{
                                      $t("labels.image")
                                    }}</span>

                                    <img
                                      v-if="item.media"
                                      :src="item.media[0].image"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      :src="item.product.image"
                                      alt=""
                                    />
                                  </div>

                                  <div class="color">
                                    <span class="key">{{
                                      $t("labels.color")
                                    }}</span>
                                    <v-chip
                                      :color="
                                        item.color ? item.color.hex : 'لا يوجد'
                                      "
                                      class="value"
                                    ></v-chip>
                                  </div>

                                  <div class="size">
                                    <span class="key">{{
                                      $t("labels.quantity")
                                    }}</span>
                                    <v-chip
                                      color="#0E3958"
                                      class="value text-white"
                                    >
                                      {{
                                        item.quantity
                                          ? item.quantity
                                          : "لا يوجد"
                                      }}
                                    </v-chip>
                                  </div>

                                  <div class="size">
                                    <div class="size">
                                      <span class="key">{{
                                        $t("labels.sizes")
                                      }}</span>
                                      <v-chip
                                        color="#0E3958"
                                        class="value text-white"
                                      >
                                        {{
                                          item.size ? item.size.name : "لا يوجد"
                                        }}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </v-data-table>
                      </main>
                    </div>
                  </div>

                  <div class="col-md-1 py-0 d-flex justify-center align-center">
                    <span
                      v-if="index == data.products_rows.length - 1"
                      class="append mx-1"
                      @click="appendProductRow"
                    >
                      <i class="fas fa-plus-circle"></i>
                    </span>

                    <span
                      v-if="data.products_rows.length > 1"
                      class="append mx-1"
                      @click="deleteProductRow(index)"
                    >
                      <i class="fas fa-minus-circle"></i>
                    </span>
                  </div>
                </div>
              </div>
            </template>

            <!-- Select Categories -->
            <ValidationProvider
              v-if="
                data.applly_coupon_on.id == 'special_categories' ||
                data.applly_coupon_on.id == 'except_categories'
              "
              v-slot="{ errors }"
              :name="$t('labels.category')"
              class="col-md-12"
              rules="required"
            >
              <div class="input_wrapper">
                <treeselect
                  dir="rtl"
                  :multiple="true"
                  :placeholder="$t('labels.category')"
                  :options="prods_and_categories"
                  :show-count="true"
                  :flat="true"
                  v-model="data.category_ids"
                  class="p-15"
                />
              </div>
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>

          <div class="button_wrapper center">
            <v-btn
              type="submit"
              @click="submit"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t("submit") }}
            </v-btn>
          </div>
          <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// import MapWrite from "../../../../components/Maps/MapWrite.vue";

export default {
  // components: { MapWrite },
  props: ["id"],
  data() {
    return {
      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.coupons.title"),
          disabled: false,
          href: "/coupon/show-all",
        },
        {
          text: this.$t(`breadcrumb.coupons.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      loading: false,
      headers: [
        {
          text: this.$t("labels.product"),
          align: "center",
          value: "item",
          sortable: true,
        },
      ],
      data: {
        price: null,
        applly_coupon_on: {
          id: null,
          name: null,
        },
        end_time: null,
        start_time: null,
        addition_option: {
          id: null,
          name: null,
        },
        category_ids: [],
        code: null,
        start_at: null,
        end_at: null,
        user_type: null,
        discount_type: {
          id: null,
          name: null,
        },
        apply_ids: [],
        usage_count: null,
        user_usage_count: null,
        is_active: false,

        products_rows: [
          {
            product: null,
            product_details_show: [],
            product_details_ids: [],
            selected: [],
          },
        ],

        avatar: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
      },

      addition_options: [
        {
          id: "free_shipping",
          name: "توصيل مجاني",
        },
      ],
      categories: [],
      prods_and_categories: [],

      discountTypes: [
        {
          id: "value",
          name: this.$t("monyAmount"),
        },
        {
          id: "percentage",
          name: this.$t("percentage"),
        },
      ],
      applly_coupon_on_types: [
        {
          id: "all",
          name: this.$t("appliedCoupoun.all"),
        },
        {
          id: "special_products",
          name: this.$t("appliedCoupoun.special_products"),
        },
        {
          id: "except_products",
          name: this.$t("appliedCoupoun.except_products"),
        },
        {
          id: "special_categories",
          name: this.$t("appliedCoupoun.special_categories"),
        },
        {
          id: "except_categories",
          name: this.$t("appliedCoupoun.except_categories"),
        },
      ],
    };
  },
  methods: {
    async getListOfProductsAndCaterogries(type) {
      let url;
      if (type == "special_products" || type == "except_products") {
        url = "product/without_paginate";
      } else if (type == "special_categories" || type == "except_categories") {
        url = "category/treet";
      } else if (type == "all") return;

      await this.axios.get(url).then((res) => {
        console.log(res);
        this.prods_and_categories = res.data.data;
      });
    },
    getProductDetails(index, id) {
      this.axios({
        method: "GET",
        url: `product-details-v2/${id}`,
      }).then((res) => {
        this.data.products_rows[index].product_details_ids = [];
        this.data.products_rows[index].product_details_show =
          res.data.data.product_details;
      });
    },
    //Products
    appendProductRow() {
      this.data.products_rows.push({
        product: null,
        product_details_show: [],
        product_details_ids: [],
        selected: [],
      });
    },
    deleteProductRow(index) {
      this.data.products_rows.splice(index, 1);
    },
    getEditData() {
      this.noData = false;
      this.loading = true;
      this.axios({
        method: "GET",
        url: `coupon/${this.id}`,
      })
        .then((res) => {
          this.loading = false;
          const result = res.data.data;
          this.data.avatar.preview = result.image;
          this.data.code = result.code;
          this.data.applly_coupon_on.id = result.applly_coupon_on;
          this.data.usage_count = result.max_used_num;
          this.data.user_usage_count = result.max_used_for_user;
          this.data.start_time = result.start_time;
          this.data.end_time = result.end_time;
          this.data.discount_type.id = result.discount_type;
          this.data.discount_type.name = result.discount_type;
          this.data.addition_option.id = result.addtion_options;
          this.data.discount_amount = result.discount_amount;
          this.data.is_active = result.is_active;
          this.data.start_at = new Date(result.start_at).toLocaleDateString(
            "en-CA"
          );
          this.data.end_at = new Date(result.end_at).toLocaleDateString(
            "en-CA"
          );
          if (this.data.applly_coupon_on.id != "all") {
            this.getListOfProductsAndCaterogries(result.applly_coupon_on);
          }

          if (
            result.applly_coupon_on == "special_categories" ||
            result.applly_coupon_on == "except_categories"
          ) {
            this.data.category_ids = result.applly_on_category.map(
              (el) => el.category_id
            );
          }

          // Products
          if (
            result.applly_coupon_on == "special_products" ||
            result.applly_coupon_on == "except_products"
          ) {
            this.data.products_rows = [];
            result.apply_on_product.map((el) => {
              const selectedProductDetailsId = el.product_details_id.map(
                (el) => el.toString() // Convert product detail ids to strings for comparison
              );

              // Map selected product details to an array of objects with id property
              const selectedProductDetails = el.product_details_show.map(
                (detail) => ({
                  id: detail.id.toString(), // Convert id to string for comparison
                })
              );

              this.data.products_rows.push({
                product: el.product,
                product_details_show: el.product_details_show,
                selected: selectedProductDetails.filter((detail) =>
                  selectedProductDetailsId.includes(detail.id)
                ), // Filter selected product details based on the product details id
              });
            });
          }
        })
        .catch((err) => {
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
          this.noData = true;
          this.loading = false;
        });
    },

    async uploadImage(event, modal, varName) {
      if (event) {
        this.btnLoading = true;
        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", modal);

        await this.axios({
          method: "POST",
          url: "attachments",
          data: data,
        })
          .then((res) => {
            this.data[varName].image = res.data.data;
            this.data[varName].preview = URL.createObjectURL(event);
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });

            this.btnLoading = false;
          });
      }
    },
    submit() {
      this.$refs.formCoupon.validate().then((d) => {
        if (d) {
          this.loading = true;
          const data = new FormData();

          if (this.data.avatar.image) {
            data.append("image", this.data.avatar.image);
          }

          data.append("code", this.data.code);
          data.append("discount_amount", this.data.discount_amount);

          data.append("discount_type", this.data.discount_type?.id);
          data.append("max_used_num", this.data.usage_count);
          data.append("max_used_for_user", this.data.user_usage_count);
          data.append("is_active", +this.data.is_active);
          data.append(
            "start_at",
            `${this.$moment(this.data.start_at).format("YYYY-MM-DD")} ${
              this.data.start_time
            }`
          );
          data.append(
            "end_at",
            `${this.$moment(this.data.end_at).format("YYYY-MM-DD")} ${
              this.data.end_time
            }`
          );

          // data.append("start_time", this.data.start_time);
          // data.append("price", this.data.price);
          // data.append("price", this.data.price);
          data.append("applly_coupon_on", this.data.applly_coupon_on?.id);

          if (this.data.addition_option) {
            data.append("addtion_options", this.data.addition_option.id);
          }
          // Append Categories
          if (
            this.data.applly_coupon_on.id == "special_categories" ||
            this.data.applly_coupon_on.id == "except_categories"
          ) {
            this.data.category_ids.map((el, index) => {
              data.append(`apply_ids[${index}]`, el);
            });
          }
          // Append Products
          // if (
          //   this.data.applly_coupon_on.id == "except_products" ||
          //   this.data.applly_coupon_on.id == "special_products"
          // ) {
          //   // if (this.data.products_rows.selected.length) {
          //   //   this.data.products_rows.map((el) => {
          //   //     el.selected.map((el, index) => {
          //   //       data.append(`apply_ids[${index}]`, el.id);
          //   //     });
          //   //   });
          //   // }
          //   this.data.products_rows &&
          //     this.data.products_rows.forEach((row, index) => {
          //       row.selected.forEach((el) => {
          //         data.append(`apply_ids[${index}]`, el.id);
          //       });
          //     });
          // }

          if (
            this.data.applly_coupon_on.id == "except_products" ||
            this.data.applly_coupon_on.id == "special_products"
          ) {
            let ids = [];
            if (this.data.products_rows[0].selected.length) {
              this.data.products_rows.map((el) => {
                el.selected.map((el) => {
                  ids.push(el.id);
                });
              });
            }
            if (ids.length) {
              [...new Set(ids)].map((item, index) => {
                data.append(`apply_ids[${index}]`, item);
              });
            }
          }

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `coupon/${this.id}`;
          } else {
            url = "coupon";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.loading = false;
              this.$router.push("/coupon/show-all");
            })
            .catch((err) => {
              this.loading = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message:
                  err.response.data.message ?? err.response.data.messages,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
    sentImage(event) {
      if (event) {
        this.data.avatar.preview = URL.createObjectURL(event);
        this.data.avatar.image = event;
      }
    },
  },
  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.canEdit = this.$permission.allowed("coupon/edit");
    this.canDelete = this.$permission.allowed("coupon/delete");
    this.canAdd = this.$permission.allowed("coupon/add");
    this.canShow = this.$permission.allowed("coupon/show");
    this.canShowAll = this.$permission.allowed("coupon/show-all");
    if (this.id) {
      this.getEditData();
    }
  },
};
</script>
<style lang="scss">
.p-15 {
  padding: 6px;
}
.productRow {
  // border: 1px solid #00a0e6;
  border-radius: 15px;
  // padding: 10px 10px 20px 10px;
  margin-bottom: 20px;

  .detail {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .detail_show {
      // border: 1px solid #00a0e6;
      // padding: 10px;
      flex-grow: 1;
      border-radius: 15px;
      position: relative;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .rate {
        color: gray;
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 13px;

        span {
          color: yellow;
        }
      }

      .image {
        img {
          max-height: 50px;
        }
      }

      .color,
      .size {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .key {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
