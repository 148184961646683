export default class Permission {
  setControls(path) {
    let status;
    if (
      localStorage.getItem("myTeam_Academy_Dash_User_Type") != "super_admin"
    ) {
      if (localStorage.getItem("myTeam_controls")) {
        let controls = JSON.parse(localStorage.getItem("myTeam_controls"));

        const found = controls.find((control) => control.url == path);

        if (found) {
          status = false;
        } else {
          status = true;
        }
      }
    } else {
      status = false;
    }
    return status;
  }
  allowed(path) {
    let status;
    if (
      localStorage.getItem("myTeam_Academy_Dash_User_Type") != "super_admin" &&
      localStorage.getItem("myTeam_Academy_Dash_User_Type") != "academy"
    ) {
      if (localStorage.getItem("myTeam_Academy_Dash_permissions")) {
        let controls = JSON.parse(
          localStorage.getItem("myTeam_Academy_Dash_permissions")
        );
        const found = controls.find((control) => control?.url == path);
        console.log(found?.url);
        if (found) {
          status = false;
        } else {
          status = true;
        }
      }
    } else {
      status = false;
    }
    return status;
  }

  // allowed(path) {
  //   let status = false; // Default to not allowed
  //   const userType = localStorage.getItem("myTeam_Academy_Dash_User_Type");

  //   if (userType !== "super_admin" && userType !== "academy") {
  //     const permissions = localStorage.getItem(
  //       "myTeam_Academy_Dash_permissions"
  //     );

  //     if (permissions) {
  //       const controls = JSON.parse(permissions);
  //       const found = controls.find((control) => control?.url === path);
  //       status = !found; // If found, allowed; otherwise, not allowed
  //     } else {
  //       status = true; // If no permissions exist, deny access
  //     }
  //   }

  //   return status;
  // }
}
