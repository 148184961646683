<template>
  <div class="countries_all">
    <template>
      <!-- <the-filter :inputs="inputs" /> -->

      <!-- @filterFired="filterFired" -->
      <Breadcrumb
        :canAddNew="!canAdd"
        :items="items"
        search_route="/airport-terminals/add"
        :search_title="$t('breadcrumb.vehicles.add')"
        icon="fa-plus"
      />
      <!-- <the-filter :inputs="inputs" /> -->
      <!-- :custom-filter="helper_filterSearch" -->

      <main>
        <div class="col-12 mb-2 pt-0">
          <div class="row">
            <simple-card v-for="(card, index) in statistics" :key="index">
              <statistics-card :item="card" :color="getRandomColor()" />
            </simple-card>
          </div>
        </div>

        <v-data-table
          class="thumb strip"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :expanded.sync="expanded"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- IsActive -->
          <template v-slot:[`item.is_active`]="{ item }">
            <span
              class="status"
              :class="item.is_active ? 'active' : 'inactive'"
            >
              {{ item.is_active ? $t("active") : $t("inactive") }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon
                class="edit"
                v-if="!canEdit"
                small
                @click="editItem(item)"
              >
                fal fa-edit
              </v-icon>

              <deleter
                v-if="!canDelete"
                :items="rows"
                name="terminal"
                :id="item.id"
                @refresh="rows = $event"
              />
            </div>
          </template>
        </v-data-table>

        <!-- Start Pagination -->
        <template>
          <div
            class="pagination_container text-center mb-5 d-flex justify-content-end"
          >
            <v-pagination
              color="primary"
              v-model="paginations.current_page"
              :length="paginations.last_page"
              :total-visible="5"
              @input="fetchData($event)"
            ></v-pagination>
          </div>
        </template>
        <!-- End Pagination -->
      </main>

      <!-- Start Image_Model -->
      <base-model
        @close="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image text-center">
          <img
            class="img-fluid"
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
      <!-- End Image_Model -->
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.airport.title"),
          disabled: false,
          href: "/vehicles/show-all",
        },
        {
          text: this.$t("breadcrumb.airport.all"),
          disabled: true,
          href: "",
        },
      ],
      expanded: [],

      loading: false,
      button_: {
        id: "",
      },
      // ========== headers
      headers: [
        {
          text: this.$t("labels.ordering"),
          align: "center",
          value: "ordering",
          sortable: false,
        },
        {
          text: this.$t("labels.airportName"),
          align: "center",
          value: `${
            this.$i18n.locale === "ar" ? "ar.airport_name" : "en.airport_name"
          }`,
          sortable: false,
        },
        {
          text: this.$t("labels.name"),
          align: "center",
          value: `${this.$i18n.locale === "ar" ? "ar.name" : "en.name"}`,
          sortable: false,
        },
        {
          text: this.$t("labels.is_active"),
          align: "center",
          value: "is_active",
          sortable: false,
        },
        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      statistics: [],
      // ========== Filter
      inputs: [
        {
          keyName: "keyword",
        },

        {
          keyName: "model_id",
          url: "/vehicle_model_not_paginated", // For Auto Complete To Search
        },
        {
          keyName: "package_id",
          url: "/vehicle_package_not_paginated", // For Auto Complete To Search
        },
        {
          keyName: "manufacturing_year",
        },
      ],
      rows: [],
      search: null,
      expandData: null,
    };
  },
  watch: {
    [`$route`]() {
      if (this.$route.query.page) {
        this.paginations.current_page = +this.$route.query.page;
        this.setRows();
      } else {
        this.paginations.current_page = 1;
        this.setRows();
      }
    },
  },
  methods: {
    imageError(index) {
      this.rows[
        index
      ].image = require("@/assets/media/images/Icons/no-data.png");
    },
    mapItem(data) {
      this.expandData = {
        [this.$t("labels.manufacturing_year")]: data.manufacturing_year,
        [this.$t("labels.license_number")]: data.license_number,
        [this.$t("labels.license_serial_number")]: data.license_serial_number,

        [this.$t("labels.plate_number")]: data.plate_number,

        [this.$t("labels.created_at")]: data.created_at,
      };
    },
    async setRows() {
      this.loading = true;

      await this.axios({
        method: "GET",
        url: "terminal",
        params: {
          page: this.paginations.current_page,
          key_word: this.$route.query.keyword,
        },
      })
        .then((res) => {
          console.log(res.data.data);

          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;
          this.total = res.data.meta?.total;
          this.rows = res.data.data;
          this.statistics = [];
          // for (const [key, value] of Object.entries(res.data.statistics)) {
          //   this.statistics.push({
          //     title: this.$t(key),
          //     count: value,
          //     route:
          //       key == "active_vehicle_count"
          //         ? "/vehicles/show-all?is_active=1"
          //         : key == "deactivate_vehicle_count"
          //         ? "/vehicles/show-all?is_active=0"
          //         : key == "vehicles_count"
          //         ? "/vehicles/show-all"
          //         : "/vehicles/show-all?is_locked=1",
          //   });
          // }
        })
        .catch((err) => {
          let message =
            err.response?.data.message ?? err.response?.data.messages;
          this.$iziToast.error({
            title: this.$t("validation.error"),
            message: message,
          });
        })
        .finally(() => (this.loading = false));
    },
    clickRow(rowData) {
      if (this.noExpand) return;
      const indexExpanded = this.expanded.findIndex((i) => i === rowData);
      if (indexExpanded > -1) {
        // Exisit
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded = [];
        this.expanded.push(rowData);
        this.mapItem(rowData);
        // this.expandData = rowData
      }
    },
    showItem(item) {
      this.$router.push({ path: `/airport-terminals/show/` + item.id });
    },
    editItem(item) {
      this.$router.push({ path: `/airport-terminals/edit/` + item.id });
    },
    fetchData(e) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: e }),
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.setRows();
    },
    changeLockStatus(item) {
      this.button_.id = item.id;
      this.axios
        .post(`vehicle/${item.id}/update-is_lock`)
        .then(() => {
          this.button_.id = "";
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message:
              item.is_locked == 1 ? "تم حجب السيارة" : "تم الغاء حجب السيارة",
          });
        })
        .catch((err) => {
          this.button_.id = "";
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
    changeTripStatus(item) {
      this.button_.id = item.id;
      this.axios
        .post(`vehicle/${item.id}/update-is_in_order`)
        .then(() => {
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message:
              item.is_in_order == 1
                ? "تم وضع السياره في رحلة"
                : "تم الغاء الرحلة ",
          });
          this.button_.id = "";
        })
        .catch((err) => {
          this.button_.id = "";
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
  },
  created() {
    this.canEdit = this.$permission.allowed("vehicles/edit/");
    this.canAdd = this.$permission.allowed("vehicles/add");
    this.canDelete = this.$permission.allowed("vehicles/delete");
    this.canShow = this.$permission.allowed("vehicles/show/");
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },
};
</script>
