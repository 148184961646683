<template>
  <div class="vehicle_show">
    <Breadcrumb :items="items" />

    <Loader v-if="loading"></Loader>

    <!-- Start:: Data Cards -->
    <div v-else class="data_cards_wrapper fadeIn">
      <noData v-if="noData" />

      <div class="row" v-else>
        <!-- ********** Start:: Main Data ********** -->
        <div class="col-lg-12 my-0 my-md-2 d-block d-md-flex">
          <!-- <vehicles-data :vehiclesData="elementData" /> -->
        </div>
        <!-- ********** End:: Main Data ********** -->
      </div>
    </div>
    <!-- End:: Data Cards -->

    <!-- Start Image_Model -->
    <ImageModal
      :modalIsOpen="model_1.show_model"
      :modalImage="model_1.model_img_src"
      @toggleModal="model_1.show_model = false"
    />
    <!-- End Image_Model -->
  </div>
</template>

<script>
import ImageModal from "@/components/Modals/ImageModal.vue";
import VehiclesData from "./VehiclesData.vue";

export default {
  components: {
    ImageModal,
    VehiclesData,
  },

  props: {
    id: {
      required: true,
    },
  },

  data() {
    return {
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.airport.title"),
          disabled: false,
          href: "/vehicles/show-all",
        },
        {
          text: this.$t("breadcrumb.airport.details"),
          disabled: true,
          href: "",
        },
      ],

      noData: false,
      // ========== headers
      headers: [
        {
          text: this.$t("labels.rider"),
          align: "center",
          value: "clientName",
          sortable: false,
        },
        {
          text: this.$t("labels.partner"),
          align: "center",
          value: "driverName",
          sortable: false,
        },
        {
          text: "المسافه الفعليه",
          align: "center",
          value: "actual_distance",
          sortable: false,
        },
        {
          text: "المده الفعليه",
          align: "center",
          value: "actual_order_duration",
          sortable: false,
        },
        {
          text: "السعر",
          align: "center",
          value: "total_price",
          sortable: false,
        },
        {
          text: "الحالة",
          value: "statusOrder",
          align: "center",
        },
        {
          text: "تاريخ الرحله",
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("labels.control"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],

      // ========== headers
      headers2: [
        {
          text: "صورة رخصة السيارة",
          align: "center",
          value: "vehicleLicenseImage",
          sortable: false,
        },
        {
          text: "صورة لوحة السيارة",
          align: "center",
          value: "plateImage",
          sortable: false,
        },
      ],

      // ========== headers
      elementData: null,

      // Start:: Model Data
      model_1: {
        show_model: false,
        model_img_src: "",
      },
      // End:: Model Data

      tabs: null,
      loading: false,
      files: [],
      vehicles: [],
    };
  },

  methods: {
    // Start:: Get Element Data
    async getElementData() {
      this.noData = true;
      this.loading = true;
      await this.axios({
        method: "GET",
        url: `terminal/${this.id}`,
      })
        .then((res) => {
          this.loading = false;
          this.elementData = res.data.data;

          this.noData = false;
          this.vehicles = [
            {
              id: 1,
              vehicleLicenseImage: this.elementData.vehicle_license_image,
              plateImage: this.elementData.plate_image,
            },
          ];
        })
        .catch((err) => {
          this.noData = true;
          this.loading = false;
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
    // End:: Get Element Data

    // Start:: Img Model
    show_model_1(src) {
      this.model_1.model_img_src = src;
      this.model_1.show_model = true;
    },
    // End:: Img Model
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    // Start:: Fire Methods
    this.getElementData();
  },
};
</script>

<style lang="scss">
.info_tabs {
  margin-bottom: 50px;
}
.v-tabs {
  padding: 20px 0 0;
  .v-tab {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 01px;
  }
}
.v-sheet.v-card {
  box-shadow: 0 0 0;
}
.vehicle_show {
  .vehicle_images_wrapper {
    margin-block: 15px 40px;
    @include flexCenterAlignment;
    column-gap: 70px;
    .image_wrapper {
      .image_title {
        margin-bottom: 15px;
        color: var(--mainText);
      }
      img {
        border-radius: 20px;
        object-fit: cover;
      }
    }
  }
  .data_cards_wrapper {
    margin-top: 25px;
    .card_data {
      width: 100%;
      padding: 20px;
      background: var(--lightBg);
      border-radius: 20px;
      .title_wrapper {
        @include flexCenterAlignment;
        .card_data_title {
          margin-bottom: 20px;
          position: relative;
          text-align: center;
          width: max-content;
          color: var(--);
          &::before {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translate(-50%);
            display: block;
            width: 60%;
            height: 2px;
            border-radius: 15px;
            background-color: var(--mainText);
          }
        }
      }

      .card_data_items {
        .card_data_item {
          @include flexStartAlignment;
          column-gap: 8px;
          &:not(:last-child) {
            margin-bottom: 1.2rem;
          }

          span {
            font-size: 1.2rem;
            color: var(--mainText);
            &.key {
              font-weight: 600;
              font-size: 1rem;
            }
            &.value {
              font-size: 1.2rem;
            }
          }
          button {
            &.key {
              color: var(--mainText);
              font-weight: 600;
              font-size: 1rem;
            }
          }
          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #80c2ff;
            border-radius: 50%;
            // border: 2px solid var(--main);
          }
        }
      }
    }
  }
}
.tabCount {
  font-size: 14px;
  padding: 5px 16px;
  position: relative;
  right: 10px;
  background: var(--main);
  color: var(--white);
  border-radius: 10px;
}
// ============
.client_data {
  @include alightFlexStartAlignment;
  padding: 2rem 1rem;
  width: 100%;
  .data_user {
    margin-inline-start: 2rem;
    width: 100%;
    h4 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
  .image_client {
    @include flexCenterAlignment;
    button {
      width: 150px;
      height: 150px;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        &.img {
          object-fit: cover;
        }
      }
    }
  }
  ul {
    flex-wrap: wrap;
    @include flexCenterAlignment;
    li {
      width: 49%;
      font-size: 1rem;
      margin-bottom: 1rem;
      @include flexStartAlignment;
      border-left: 3px solid #80c2ff;
      padding-inline-end: 1rem;
      margin-inline-end: 1%;
      h5 {
        position: relative;
        font-size: 1rem;
        margin-bottom: 0;
        margin-left: 5px;
        @include flexCenterAlignment;
      }
      @include flexStartAlignment;

      i {
        margin-inline-end: 0.5rem;
        color: var(--main);
      }
    }
  }
  .info_tabs {
    margin-bottom: 2rem;
  }
}

@media (max-width: 850px) {
  .info_tabs {
    margin-bottom: 25px 0 0;
  }
  .v-tabs {
    padding: 10px;
    .v-item-group {
      height: unset;
      .v-slide-group__wrapper {
        flex-direction: column;
        .v-slide-group__content {
          flex-wrap: wrap;
          row-gap: 15px;
          .v-tabs-slider-wrapper {
            display: none;
          }
        }
      }
    }
    .v-slide-group__prev {
      display: none !important;
    }
    .v-tab {
      font-size: 14px;
      margin: 0 !important;
      width: 50%;
      max-width: 50%;
      min-width: 50%;
      justify-content: flex-start;
      .tabCount {
        padding: 5px 10px;
      }
    }
  }
  .vehicle_show {
    .data_cards_wrapper {
      margin-top: 0;
    }
  }
  .client_data {
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    .data_user {
      margin-inline-start: 0.5rem;
    }
    ul {
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        width: 100%;
        font-size: 1rem;
        margin-bottom: 1rem;
        @include flexStartAlignment;
        border-left: none;
        border-inline-start: 3px solid #80c2ff;
        padding-inline-start: 0.5rem;
        padding-inline-end: 0;
        h5 {
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .vehicle_show {
    .data_cards_wrapper {
      margin-top: 0;
    }
  }
}
</style>
