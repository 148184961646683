<template>
  <div class="state_card">
    <div class="content">
      <h5>{{ name }}</h5>
    </div>

    <div class="icon">
      <button v-if="icon" type="button" @click="actions">
        <v-icon>{{ icon }}</v-icon>
      </button>
      <p>
        <span class="value">{{ value }}</span> {{ text }}
      </p>
    </div>
    <div class="icon_logo">
      <img src="@/assets/media/images/Icons/logo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "value", "text", "icon"],
  methods: {
    actions() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss">
.state_card {
  background: white;
  border-radius: 15px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  box-shadow: 0 0 10px #ebebeb;
  position: relative;
  // &::after {
  //   position: absolute;
  //   content: "";
  //   width: 112px;
  //   height: 50px;
  //   background-size: 100px;
  //   left: 5px;
  //   top: 11px;
  // }
  .content {
    display: flex;
    flex-direction: column;
    // min-height: 70px;
    position: relative;
  }
  h5 {
    font-size: 1.25rem !important;
    font-weight: 500;
    letter-spacing: 0.0125em !important;
    text-transform: capitalize;
    color: #00a0e6;
    margin-bottom: 15px;
    position: relative;
  }
  p {
    // margin: auto 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin: 0;

    color: #ffffff;
    span.value {
      // font-style: normal;
      // font-weight: 700;
      // font-size: 25px;
      // line-height: 30px;
      color: black;

      margin: 0;
      font-size: 22px;
      word-break: break-all;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -moz-column-gap: 15px;
    column-gap: 15px;
    button {
      //   background: #ffffff;
      //   border: 2.5px solid #2e5599;
      //   width: 30px;
      //   height: 30px;
      //   border-radius: 50%;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   box-shadow: none !important;
      .v-icon {
        color: #00a0e6 !important;
        //     font-size: 16px !important;
      }
    }
  }
  .icon_logo {
    position: absolute;
    top: 10px;
    left: 5px;
    width: 50px;
    height: 50px;
  }
}

@keyframes buncing {
  0% {
    transform: translateY(0) scale(0.8);
  }
  50% {
    transform: translateY(-10px) scale(1);
  }
  100% {
    transform: translateY(0) scale(0.8);
  }
}

// body[dir="rtl"] {
//   .state_card {
//     .icon {
//       position: absolute;
//       top: 8px;
//       right: unset;
//       left: 8px;
//     }
//   }
// }
</style>
